nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73, 79, 82);
  color: white;
}


.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

fieldset.filters,
fieldset.report {
  margin: 10px auto;
  border: 1px solid green;
}

fieldset.filters legend,
fieldset.report legend {
  margin: 10px;
  padding: 0.2em 0.5em;
  border: 1px solid green;
  color: green;
  font-size: 16px;
  width: auto;
}

fieldset.filters .filters-body,
fieldset.report .report-body {
  padding: 10px;
}

fieldset.filters .filters-footer,
fieldset.report .report-footer {
  border-top: 1px solid green;
  padding: 10px;
}