
a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

.button .svgIcon {
  vertical-align: middle;
  fill: rgba(0, 0, 0, 0.54);
  padding-right: 4px;
  height: 75px;
  display: inline-block;
}
